import { getFrequencyByNote } from './noteFrequencies';

export const oscTypes = ['sine', 'square', 'sawtooth', 'triangle'];

export const runOsc = (osc, oscType, note) => {
  const frequency = getFrequencyByNote(note);

  if (frequency) {
    const audioContext = new window.AudioContext();

    if (osc) {
      osc.stop(0);
      osc = null;
    }

    if (window.audioContext) {
      let audioContextToClose = window.audioContext;

      setTimeout(() => {
        audioContextToClose.close().then(() => {
          audioContextToClose = null;
        });
      }, 1000);
    }

    osc = audioContext.createOscillator();
    osc.type = oscType;

    osc.connect(audioContext.destination);
    osc.frequency.value = frequency;
    osc.start();
    osc.stop(1);

    window.audioContext = audioContext;
  }

  return osc;
};
