const keyCodeNotes = [
  { keyCodes: [89, 90], note: 'C0' }, // y and z
  { keyCode: 83, note: 'C#0' },
  { keyCode: 88, note: 'D0' },
  { keyCode: 68, note: 'D#0' },
  { keyCode: 67, note: 'E0' },
  { keyCode: 70, note: '#' },
  { keyCode: 86, note: 'F0' },
  { keyCode: 71, note: 'F#0' },
  { keyCode: 66, note: 'G0' },
  { keyCode: 72, note: 'G#0' },
  { keyCode: 78, note: 'A0' },
  { keyCode: 74, note: 'A#0' },
  { keyCode: 77, note: 'B0' },
  { keyCode: 75, note: '#' },
  { keyCode: 188, note: 'C1' },
];

const getKeyCodeNotes = octave => keyCodeNotes.map(keyCodeNote => ({
  ...keyCodeNote,
  note: keyCodeNote.note.replace('1', Number.parseInt(octave, 10) + 1).replace('0', octave),
}));

const getNoteByKeyCode = (keyCode, octave) => getKeyCodeNotes(octave)
  .find(item => item.keyCode === keyCode || (item.keyCodes || []).includes(keyCode))?.note;

const isWhiteKey = key => key.indexOf('#') === -1;
const isKey = key => key !== '#';

const getWhiteKeys = octave => getKeyCodeNotes(octave).filter(item => isWhiteKey(item.note));
const getBlackKeys = octave => getKeyCodeNotes(octave).filter(item => !isWhiteKey(item.note));

export {
  isKey,
  getWhiteKeys,
  getBlackKeys,
  getKeyCodeNotes,
  getNoteByKeyCode,
};
