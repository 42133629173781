import { useRef, useState } from 'react';

import { oscTypes, runOsc } from '../../helpers/oscillator';
import { demoSong } from '../../helpers/song';
import { Grid } from './Grid/Grid';
import { Keyboard } from './Keyboard/Keyboard';
// import 'reset-css';
import './Sequencer.css';

const trackLength = 128;

const octaves = [0, 1, 2, 3, 4, 5, 6];

const handleKeyDown = e => {
  switch (e.keyCode) {
    default:
      // eslint-disable-next-line no-console
      console.log(e.keyCode);
  }
};

export const Sequencer = () => {
  const timer = useRef();
  const songIndex = useRef(0);
  const [osc, setOsc] = useState(null);
  const [oscType, setOscType] = useState(oscTypes[0]);
  const [isSongPlaying, setIsSongPlaying] = useState(false);
  const [song, setSong] = useState(demoSong);
  const [octave, setOctave] = useState(octaves[3]);

  const playSound = note => {
    setOsc(runOsc(osc, oscType, note));
  };

  const playSong = () => {
    setIsSongPlaying(true);
    clearTimeout(timer.current);
    timer.current = setTimeout(playSong, 1000 / ((4 * song.bpm) / 60));
    for (const track of song.tracks) {
      playSound(track[songIndex.current]);
    }
    if (songIndex.current === trackLength - 1) {
      songIndex.current = 0;
    } else {
      songIndex.current++;
    }
  };

  const pauseSong = () => {
    setIsSongPlaying(false);
    clearTimeout(timer.current);
  };

  const toggleSong = () => {
    if (isSongPlaying) {
      pauseSong();
    } else {
      playSong();
    }
  };

  const stopSong = () => {
    pauseSong();
    songIndex.current = 0;
  };

  return (
    <div className="Sequencer" onKeyDown={handleKeyDown}>
      <Grid song={song} setSong={setSong} songIndex={songIndex.current} />
      <div className="buttons">
        <div className="controls">
          <button type="button" className="button" onClick={toggleSong}>
            play/pause
          </button>
          <button type="button" className="button" onClick={stopSong}>
            stop
          </button>
        </div>
        <div className="settings">
          <select
            className="select select-osc"
            onChange={e => setOscType(e.target.value)}
            defaultValue={oscType}
          >
            {oscTypes.map(item => (
              <option key={item}>
                {item}
              </option>
            ))}
          </select>
          <select
            className="select select-octave"
            onChange={e => setOctave(e.target.value)}
            defaultValue={octave}
          >
            {octaves.map(item => (
              <option key={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Keyboard
        playSound={playSound}
        octave={octave}
      />
    </div>
  );
};
