import { useEffect, useRef } from 'react';

import { getRandom } from '../../helpers/common/math';

import './Draw.css';

export const Draw = () => {
  const canvasRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', start);
    document.addEventListener('mouseup', stop);
    window.addEventListener('resize', resize);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const coord = { x: 0, y: 0 };

    function resize() {
      ctx.canvas.width = window.innerWidth;
      ctx.canvas.height = window.innerHeight;
    }

    function start(event) {
      document.addEventListener('mousemove', draw);
      reposition(event);
    }

    function reposition(event) {
      coord.x = event.clientX - canvas.offsetLeft;
      coord.y = event.clientY - canvas.offsetTop;
    }

    function stop() {
      document.removeEventListener('mousemove', draw);
    }

    function draw(event) {
      ctx.beginPath();
      // ctx.lineWidth = 5;
      ctx.lineWidth = getRandom(5, 20);
      ctx.lineCap = 'round';
      // ctx.strokeStyle = '#ACD3ED';
      ctx.strokeStyle = `rgb(${getRandom(0, 255)}, ${getRandom(0, 255)}, ${getRandom(0, 255)})`;
      ctx.moveTo(coord.x, coord.y);
      reposition(event);
      ctx.lineTo(coord.x, coord.y);
      ctx.stroke();
    }

    resize();
  }, []);

  return <canvas className="canvas" ref={canvasRef} />;
};
