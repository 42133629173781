import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import './App.css';
import { Draw } from './components/Draw/Draw';
import { Sequencer } from './components/Sequencer/Sequencer';
import { Tour } from './components/Tour/Tour';
import { Image } from './components/common/Image';
import { Link } from './components/common/Link';
import { getRandom } from './helpers/common/math';

const routes = [{
  path: 'draw',
  Component: Draw,
}, {
  path: 'sequencer',
  Component: Sequencer,
}, {
  path: 'tour',
  Component: Tour,
}];

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <header className="App-header">
              <Link
                className="App-link"
                to={routes[getRandom(0, routes.length - 1)].path}
              >
                <Image src="./favicon.ico" className="App-logo" alt="logo" />
              </Link>
            </header>
          } />
          {routes.map(({ path, Component }) => (
            <Route key={path} path={path} element={
              <Component />
            } />
          ))}
          <Route path="*" element={(
            <Navigate to="/" />
          )} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
