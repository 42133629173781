import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import './Grid.css';

export const Grid = ({ song, setSong, songIndex }) => {
  const { tracks } = song;
  const trackWidth = 100 / tracks.length;

  useEffect(() => {
    const scrollElement = document.querySelector('.sequencer');

    scrollElement.scrollTo({
      top: songIndex * 30,
      left: 0,
      behavior: 'smooth',
    });
  }, [songIndex]);

  return (
    <div className="sequencer">
      <div
        className="tracks"
      >
        {tracks.map((track, trackIndex) => (
          <div
            key={trackIndex}
            className={classNames('track', `track${trackIndex}`, trackIndex % 2 === 1 ? 'odd' : 'even')}
            style={{
              width: `${trackWidth}%`,
            }}
          >
            {track.map((tone, toneIndex) => (
              <div
                key={toneIndex}
                className={classNames(
                  'tone',
                  `tone-${toneIndex}`,
                  toneIndex % 2 === 1 ? 'odd' : 'even',
                  songIndex === toneIndex ? 'active' : '',
                )}
              >
                <div className="index">{toneIndex}</div>
                <input
                  className="input"
                  type="text"
                  value={tone}
                  onChange={e => {
                    song.tracks[trackIndex][toneIndex] = e.target.value;
                    setSong(song);
                    // eslint-disable-next-line no-console
                    console.log(JSON.stringify(song));
                  }}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

Grid.propTypes = {
  song: PropTypes.shape({
    tracks: PropTypes.array.isRequired,
  }).isRequired,
  setSong: PropTypes.func.isRequired,
  songIndex: PropTypes.number.isRequired,
};
