import { useEffect, useState } from 'react';

import { getRandom } from '../../helpers/common/math';

import './Tour.css';

// eslint-disable-next-line max-len
// coming from https://www.zeit.de/arbeit/2022-10/spaziergangswissenschaft-professor-umweltwahrnehmung-promenadologie?utm_source=pocket-newtab-global-de-DE

// Latitude : max/min 90.0000000 to -90.0000000
// Longitude : max/min 180.0000000 to -180.0000000

const stops = 3;
const modifier = 500;
const minutesOptions = [10, 20, 30, 60, 120];
const vehicleOptions = [
  {
    title: 'foot',
    value: 1,
  },
  {
    title: 'bike',
    value: 3,
  },
  {
    title: 'car',
    value: 6,
  },
  {
    title: 'public transport',
    value: 5,
  },
  {
    title: 'plane',
    value: 10,
  },
];

export const Tour = () => {
  const [currentCoords, setCurrentCoords] = useState({
    latitude: 53.55215135397862,
    longitude: 10.011513155997292,
  });
  const [selectedMinutes, setSelectedMinutes] = useState(minutesOptions[0]);
  const [selectedVehicle, setSelectedVehicle] = useState(vehicleOptions[0].value);

  const getRandomValue = (value, limit) => {
    return Math.max(
      -limit,
      Math.min(
        limit,
        Number.parseFloat(value) + (((getRandom(1, 2) * (getRandom(0, 1) ? -1 : 1)) / modifier) * selectedMinutes) / 50,
      ),
    );
  };

  const getRandomCoordList = (count = 1, baseCoords = {}) => {
    let lastCoords = baseCoords;
    const values = [...Array(count).keys()].map(() => {
      lastCoords = {
        latitude: getRandomValue(lastCoords.latitude, 90),
        longitude: getRandomValue(lastCoords.longitude, 180),
      };

      return `${lastCoords.latitude},${lastCoords.longitude}`;
    });

    const { latitude, longitude } = baseCoords;
    const base = `${latitude},${longitude}`;

    return [base, ...values, base].join('/');
  };

  const getGoogleLink = (coords = {}) =>
    `https://google.com/maps/dir/${getRandomCoordList(
      stops * Math.max(1, Math.round(selectedMinutes / 10 / selectedVehicle)),
      coords,
    )}`;

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      location => {
        setCurrentCoords(location.coords);
      },
      () => alert('Geolocation is not available.'),
    );
  }, []);

  const handleVehicleChange = e => {
    setSelectedVehicle(e.currentTarget.value);
  };

  const handleMinutesChange = e => {
    setSelectedMinutes(e.currentTarget.value);
  };

  return (
    <div className="tour">
      <h1>Generate random tour</h1>
      <hr />
      <form>
        <label htmlFor="vehicle">vehicle</label>
        <select id="vehicle" name="vehicle" onChange={handleVehicleChange}>
          {vehicleOptions.map(vehicle => (
            <option value={vehicle.value} key={vehicle.title}>
              {vehicle.title}
            </option>
          ))}
        </select>
        <hr />
        <label htmlFor="minutes">minutes</label>
        <select id="minutes" name="minutes" onChange={handleMinutesChange}>
          {minutesOptions.map(minutes => (
            <option value={minutes} key={minutes}>
              {minutes}
            </option>
          ))}
        </select>
        <hr />
        <button
          onClick={e => {
            e.preventDefault();
            window.open(getGoogleLink(currentCoords));
          }}
          type="submit"
        >
          go
        </button>
      </form>
    </div>
  );
};
