import PropTypes from 'prop-types';

import { getBlackKeys, getNoteByKeyCode, getWhiteKeys } from '../../../helpers/keyCodeNotes';
import { Key } from '../Key/Key';

import './Keyboard.css';

export const Keyboard = ({
  playSound,
  octave,
}) => {
  const whiteKeys = getWhiteKeys(octave);
  const blackKeys = getBlackKeys(octave);

  const handleKeyDown = e => playSound(getNoteByKeyCode(e.keyCode, octave));

  return (
    <div className="keyboard" onKeyDown={handleKeyDown}>
      {whiteKeys.map((item, index) => {
        const keyCode = item.keyCode || item.keyCodes[0];
        const note = getNoteByKeyCode(keyCode, octave);
        const width = 100 / whiteKeys.length;
        const props = {
          className: 'white',
          note,
          width: `${width}%`,
          left: `${width * index}%`,
          onClick: () => playSound(note),
        };

        return <Key key={keyCode} {...props} />;
      })}
      {blackKeys.map((item, index) => {
        const keyCode = item.keyCode || item.keyCodes[0];
        const note = getNoteByKeyCode(keyCode, octave);
        const width = 50 / whiteKeys.length;
        const props = {
          className: 'black',
          note,
          width: `${width}%`,
          left: `${2 * width * index + 1.5 * width}%`,
          onClick: () => playSound(note),
        };

        return <Key key={keyCode} {...props} />;
      })}
    </div>
  );
};

Keyboard.propTypes = {
  playSound: PropTypes.func.isRequired,
  octave: PropTypes.number.isRequired,
};
