import classNames from 'classnames';
import PropTypes from 'prop-types';

import { isKey } from '../../../helpers/keyCodeNotes';

export const Key = props => {
  const { className, width, left, note, onClick } = props;

  return isKey(note) ? (
    <button
      type="button"
      className={classNames('key', className)}
      style={{
        width,
        left,
      }}
      onClick={onClick}
    >
      {note}
    </button>
  ) : null;
};

Key.propTypes = {
  note: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  left: PropTypes.string,
  onClick: PropTypes.func,
};
